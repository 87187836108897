import { GetMe, getUserInfo } from "api";
import UserSettings from "api/UserSettings";
import { useUserContext } from "context/UserContext";
import { FC, ReactElement, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Loader } from "styled-components/Miscelaneous";

type ProtectedRouteProps = {
    redirectPath?: string;
    children?: ReactElement;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    redirectPath = "/start",
    children
}) => {
    const { state } = useLocation();
    const { token, id } = getUserInfo();
    const { setUserData } = useUserContext();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const locationState = (state as any) || false;
    const refresh = locationState?.refresh || false;

    useEffect(() => {
        (async () => {
            Promise.all([GetMe(token), UserSettings.get(id)])
                .then(([me, res]) => {
                    setUserData({
                        ...me.data.user,
                        settings: res.data
                    });
                })
                .catch(err => {
                    localStorage.removeItem("userInfo");
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    if (!token) return <Navigate to={redirectPath} />;
    if (loading) return <Loader />;
    if (!loading && error) return <Navigate to={redirectPath} replace />;

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
