import { getUserInfo } from "api";
import { RegisterContextProvider } from "context/RegisterContext";
import { useUserContext } from "context/UserContext";
import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Loader } from "styled-components/Miscelaneous";

const NoLayout = () => {
    const navigate = useNavigate();
    const { token } = getUserInfo();
    const { userData } = useUserContext();

    useEffect(() => {
        userData.id !== "" && navigate("/homepage");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, navigate]);

    return (
        <RegisterContextProvider>
            <Suspense fallback={<Loader />}>
                <Outlet />
            </Suspense>
        </RegisterContextProvider>
    );
};

export default NoLayout;
