import Box, { BoxProps } from "@mui/material/Box";

import { styled } from "@mui/material/styles";

export const MainContainer = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    position: "relative",
    flexDirection: "column"
}));

export const FullHeightContainer = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.mode === "light" ? "FFF" : "000",
    height: "calc(100vh - 7px)"
}));

export const BackButtonContainer = styled(Box)<BoxProps>(({ theme }) => ({
    position: "absolute",
    top: 40,
    left: 32,
    zIndex: 1
}));

export const CenterBox = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
}));

export const AppBox = styled(Box)<BoxProps>(({ theme }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
    padding: "78px 20px"
}));

export const SettingsButtonsBox = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30px",
    gap: "10px"
}));

export const GigTopBanner = styled(Box)<any>(() => ({
    marginTop: "70px",
    // backgroundColor: type === "error" ? "#FF8383" : "#AB5DC88C",
    display: "flex",
    color: "#FFF",
    alignItems: "center",
    padding: "0px 20px",
    width: "100%",
    height: "50px",
    marginBottom: "-40px"
}));

export const HomeTopBanner = styled(Box)<BoxProps>(({ theme }) => ({
    marginTop: "-27px",
    transform: "translateX(-20px)",
    backgroundColor: theme.palette.mode === "dark" ? "#A060CA33" : "#FF8383",
    color: theme.palette.mode === "dark" ? "#DFBCE7" : "#FFF",
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    width: "100vw",
    maxWidth: "444px",
    height: "50px",
    marginBottom: "30px"
}));

export const TitleBox = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
});
