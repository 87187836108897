type HivemindEnvironmentVariable =
    | "CURRENT_VERSION"
    | "API_URL"
    | "BASE_URL"
    | "CLOUDINARY_CLOUD_NAME"
    | "CLOUDINARY_API_KEY"
    | "BASE_REDIRECTION_URL"
    | "IMAGE_HOST_URL"
    | "MIXPANEL_TOKEN"
    | "TRACKING_ID"
    | "CHAT_API_WEBSOCKET_URL"
    | "RECAPTCHA_SITE_KEY";

interface EnvironmentVariablesStructure
    extends Record<HivemindEnvironmentVariable, string> {}

const environmentVariables: EnvironmentVariablesStructure = {
    API_URL: process.env.REACT_APP_API_URL || "http://localhost:4000",
    BASE_URL: process.env.REACT_APP_BASE_URL || "http://localhost:3000",
    BASE_REDIRECTION_URL:
        process.env.REACT_APP_BASE_REDIRECTION_URL || "localhost:3000",
    CURRENT_VERSION: `0.0.1 ${process.env.NODE_ENV}`,
    CHAT_API_WEBSOCKET_URL: process.env
        .REACT_APP_CHAT_API_WEBSOCKET_URL as string,
    CLOUDINARY_API_KEY: process.env.REACT_APP_CLOUDINARY_API_KEY as string,
    MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN as string,
    TRACKING_ID: process.env.REACT_APP_TRACKING_ID as string,
    CLOUDINARY_CLOUD_NAME: process.env
        .REACT_APP_CLOUDINARY_CLOUD_NAME as string,
    IMAGE_HOST_URL: process.env.REACT_APP_IMAGE_HOST_URL as string,
    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
};

function getEnvVariable(environmentVariable: HivemindEnvironmentVariable) {
    return environmentVariables[environmentVariable];
}

export default getEnvVariable;
