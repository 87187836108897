import { createContext, MutableRefObject, useContext } from "react";
import type { FC, Dispatch, SetStateAction, ReactNode } from "react";

import { Conversation } from "types/conversations";
import { useHivemindWebSocket } from "context/conversations/useWebsocket";
import { WebsocketMessagePayload } from "./interfaces";

interface ConversationContextProps {
    loadingWebsocket: boolean;
    triggerAction: (params: WebsocketMessagePayload) => void;
    chatBackgroundRef: MutableRefObject<HTMLElement | null>;
    pendingMessages: Record<string, number>;
    setPendingMessages: Dispatch<SetStateAction<Record<string, number>>>;

    conversations: Conversation[];

    conversationExist: (params: WebsocketMessagePayload) => boolean;
    activeConversation: Conversation | null;
    setActiveConversation: Dispatch<SetStateAction<Conversation | null>>;
}

export const ConversationContext = createContext<ConversationContextProps>({
    loadingWebsocket: true,
    triggerAction: () => {},
    chatBackgroundRef: { current: null },
    pendingMessages: {},
    setPendingMessages: messages => messages,

    conversations: [],
    conversationExist: () => false,
    activeConversation: null,
    setActiveConversation: conversation => conversation
});

interface ConversationContextProviderProps {
    children: ReactNode;
}

export const ConversationContextProvider: FC<
    ConversationContextProviderProps
> = ({ children }) => {
    return (
        <ConversationContext.Provider
            value={{
                ...useHivemindWebSocket()
            }}>
            {children}
        </ConversationContext.Provider>
    );
};

export const useConversationContext = (): ConversationContextProps => {
    const context = useContext(ConversationContext);
    if (context === undefined) {
        throw new Error(
            "useConversationContext must be used within a Provider"
        );
    }
    return context;
};
