import { useState, useCallback, useEffect, useRef } from "react";
import useSound from "use-sound";

import useWebSocket, { ReadyState } from "react-use-websocket";
import environmentVariable from "env";

import { Conversation } from "types/conversations";
import { WebsocketMessagePayload } from "context/conversations/interfaces";
import { getUserInfo } from "api";

import notificationSfx from "assets/audio/notification.mp3";

const websocketGateway = environmentVariable("CHAT_API_WEBSOCKET_URL");

export const useHivemindWebSocket = () => {
    const { id } = getUserInfo();
    const { sendJsonMessage, lastMessage /* , readyState */ } = useWebSocket(
        `${websocketGateway}?connectionOwnerId=${id}`
    );

    const [notificationSound] = useSound(notificationSfx, { volume: 0.2 });

    const [pendingMessages, setPendingMessages] = useState<
        Record<string, number>
    >(JSON.parse(localStorage.getItem("pendingMessages") as string) ?? {});

    const [loadingWebsocket, setLoadingWebsocket] = useState(true);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [activeConversation, setActiveConversation] =
        useState<Conversation | null>(null);

    const chatBackgroundRef = useRef<null | HTMLElement>(null);

    /* Parse the body of the websocket event to return usefull data */
    const parseWebsocketEvent = useCallback((event: typeof lastMessage) => {
        if (!event) return {};
        return JSON.parse(event.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const conversationExist = (params: WebsocketMessagePayload): boolean => {
        const conversation = conversations.find(
            conversation => conversation.id === params.conversationId
        );

        if (conversation) {
            setActiveConversation(conversation);
            sendJsonMessage({ ...params, action: "listmessages" });
            return true;
        }
        return false;
    };

    /* Parse every incoming event */
    useEffect(() => {
        const parsedEvent = parseWebsocketEvent(lastMessage);
        let conversationId;

        if (parsedEvent.messages)
            conversationId = parsedEvent.messages.length
                ? parsedEvent.messages[0].conversationId
                : parsedEvent.messages.conversationId;

        // Load conversations
        if (parsedEvent.conversations) {
            setConversations(conversations.concat(parsedEvent.conversations));
            setLoadingWebsocket(false);
        }
        // If incoming message from other conversation than active
        if (
            (parsedEvent.messages && !activeConversation) ||
            (parsedEvent.messages &&
                conversationId !== activeConversation?.id &&
                parsedEvent.messages.length !== 0)
        ) {
            const updatedPendingNotifications = {
                ...pendingMessages,
                [conversationId]: (pendingMessages[conversationId] ?? 0) + 1
            };
            setPendingMessages(updatedPendingNotifications);
            notificationSound();
        }
        // On message inside a chat
        if (parsedEvent.messages && activeConversation) {
            if (conversationId !== activeConversation.id) return;

            setActiveConversation({
                ...activeConversation,
                messages: activeConversation.messages.concat(
                    parsedEvent.messages
                )
            });
            // Scroll to bottom on every message reach
            setTimeout(
                () =>
                    chatBackgroundRef.current?.scroll({
                        top: chatBackgroundRef.current?.scrollHeight
                    }),
                200
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage]);

    /* Generic structure to trigger a Websocket event */
    const triggerAction = useCallback((params: WebsocketMessagePayload) => {
        sendJsonMessage({ ...params });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        localStorage.setItem(
            "pendingMessages",
            JSON.stringify(pendingMessages)
        );
    }, [pendingMessages]);

    /* Return user conversations */
    useEffect(() => {
        if (ReadyState.OPEN && !lastMessage)
            triggerAction({
                action: "listconversations",
                messageOwnerId: id
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Trigger return all message every time the open conversation changes */
    useEffect(() => {
        if (ReadyState.OPEN && activeConversation)
            triggerAction({
                action: "listmessages",
                conversationId: activeConversation.id
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeConversation?.id]);

    /* Get Websocket status */
    /* const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated"
    }[readyState]; */

    return {
        conversations,
        /* connectionStatus, */
        triggerAction,
        chatBackgroundRef,
        loadingWebsocket,
        conversationExist,
        activeConversation,
        setActiveConversation,
        pendingMessages,
        setPendingMessages
    };
};
