import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#181818",
            paper: "#1a1a1a"
        },
        primary: {
            main: "#A060CA",
            light: "#63B8FF",
            dark: "#3D105B",
            contrastText: "#FFF"
        },
        text: {
            primary: "#B3B3B3"
        },
        secondary: {
            main: "#3D105B",
            light: "#82e9de",
            dark: "#00867d",
            contrastText: "#BFBFBF"
        },
        grey: {
            50: "#FAFAFA",
            100: "#282828",
            200: "#545454",
            300: "#7E7E7E"
        },
        success: {
            main: "#79A335"
        }
    },
    typography: {
        fontSize: 13,
        fontFamily: "Poppins, sans-serif",
        allVariants: {
            lineHeight: "136%",
            fontWeight: 500
        },
        h1: {
            color: "#A060CA",
            fontWeight: 700,
            fontSize: "1.56rem",
            lineHeight: "136%",
            letterSpacing: "-0.6px"
        },
        h2: {
            color: "#B3B3B3",
            fontWeight: 700,
            fontSize: "1.375rem",
            lineHeight: "136%",
            letterSpacing: "-0.6px"
        },
        h3: {
            fontWeight: 600,
            fontSize: "0.9rem"
        },
        h6: {
            fontWeight: 600,
            fontSize: ".9rem",
            color: "#A060CA",
            lineHeight: 1,
            letterSpacing: "-0.6px"
        },
        subtitle1: {
            fontSize: ".938rem",
            color: "#878787",
            letterSpacing: "-0.36px"
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: "0.938rem",
            color: "#b3b3b3",
            letterSpacing: "-0.36px"
        },
        caption: {
            fontSize: ".75rem",
            lineHeight: 1.5
        }
    }
});

export default darkTheme;
