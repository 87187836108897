import type { Settings } from "types/user/settings";

// USER ENTITY TYPES

export type UserRole = {
    id: string;
    is_active: boolean;
    name: string;
    type: string;
};

export enum USER_VERIFICATIONS {
    PHONE = 'Initial signup',
    EMAIL = 'Confirmed email',
    ID = 'Confirmed ID',
    VIDEO = 'Submitted video',
    COMPLETED = 'Completed onboarding',
}

export enum USER_STATUS {
    ENABLED = 'Enabled',
    PENDING = 'Pending',
    REJECTED = 'Rejected',
    OUTLOGGED = 'Outlogged',
}

export interface DefaultUser {
    id: string;
    numericId: number;
    // Rating
    amountGigRate: number;
    amountRate: number;
    // Onboarding stuff
    answeredOnboarding: boolean;
    answeredOnboardingGeneral: boolean;
    stripeEnabled: boolean;
    video: string;
    videoVerified: boolean;
    // Credit
    credit: number;
    // Email & Login
    email: string;
    emailSentStatus: boolean;
    emailEnabled: boolean;
    emailToken: string;
    loginToken: string;
    // Main data
    firstName: string;
    lastName: string;
    phoneNumber: string;
    password: string;
    // Location
    lastLocation: string;
    // Status
    isActive: boolean;
    status: USER_STATUS;
    // Role
    role: UserRole;
    lastVerification: USER_VERIFICATIONS
    flowType: string[];
    flowTypeBasicCompleted: string[];
    zipcode: string;
}

// User data with additional user settings
export interface User extends DefaultUser {
    settings: Settings;
}

// Data that is saved in local storage
export interface LocalStorageUserInfo {
    id: string;
    // Main data
    firstName: string;
    lastName: string;
    phoneNumber: string;
    // Onboarding
    didAnswer: boolean;
    // TOKEN
    token: string;
}

export interface ReferralUsername {
    firstName: DefaultUser["firstName"];
    lastName: DefaultUser["lastName"];
}
