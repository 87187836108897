import {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

interface FormFields {
    step: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

interface FormData {
    formData: FormFields;
    setFormData: Dispatch<SetStateAction<FormFields>>;
    handleFormData: (key: keyof FormFields, value: string) => void;
    goBack: () => void;
    goNext: () => void;
}

export const RegisterContext = createContext<FormData>({
    formData: {
        step: 0,
        firstName: "",
        lastName: "",
        phoneNumber: ""
    },
    setFormData: formData => formData,
    handleFormData: () => null,
    goBack: () => null,
    goNext: () => null
});

export const RegisterContextProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const [formData, setFormData] = useState<FormFields>({
        step: 0,
        firstName: "",
        lastName: "",
        phoneNumber: ""
    });

    const handleFormData = (key: keyof FormFields, value: string) => {
        setFormData(prev => ({ ...prev, [key]: value }));
    };

    const goBack = () => {
        if (formData.step > 0) {
            setFormData(prev => ({ ...prev, step: prev.step - 1 }));
        }
    };

    const goNext = () => {
        if (formData.step < 5) {
            setFormData(prev => ({ ...prev, step: prev.step + 1 }));
        }
    };

    useEffect(() => {
        const session: FormFields = JSON.parse(
            localStorage.getItem("session") || "{}"
        );
        if (Object.keys(session).length > 0) {
            setFormData(prev => ({
                ...prev,
                ...session,
                step: Number(session.step)
            }));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("session", JSON.stringify(formData));
    }, [formData, formData.step]);
    return (
        <RegisterContext.Provider
            value={{ formData, setFormData, handleFormData, goBack, goNext }}>
            {children}
        </RegisterContext.Provider>
    );
};

// Hook to get the context data
export const useRegisterContext = () => {
    const context = useContext(RegisterContext);
    if (context === undefined) {
        throw new Error("useRegisterContext must be used within a Provider");
    }
    return context;
};
