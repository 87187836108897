import type { Settings } from "types/user/settings";
import { USER_STATUS, USER_VERIFICATIONS, type User, type UserRole } from "types/user";

import { GIG_TYPES } from "./gigs";
import type { GigWithRequirements } from "types/gigs";
// import Zipcode from "api/Zipcode";

// Default values for entities
// Used in Context files

// Initial values for User role
const INITIAL_ROLE: UserRole = {
    id: "",
    name: "",
    type: "",
    is_active: false
};

// Initial values for User settings
export const INITIAL_SETTINGS: Settings = {
    id: "",
    // Notifications
    newGigPosted: [],
    gigAproved: [],
    newGigPostedInstantly: [],
    newGigPostedDaily: [],
    newGigQualifyAvailableInstantly: [],
    newGigQualifyAvailableDaily: [],
    refered: [],
    videoApproved: [],
    userApproved: [],
    gigReferral: [],
    appReferral: [],
    pointsEarned: [],
    cashOut: [],
    // Threshold for cash out
    threshold: 0,
    paymentAccounts: [],
    languages: []
    // More settings here ...
};

// Initial values for User data
export const INITIAL_USERDATA: User = {
    id: "",
    numericId: 0,
    // Rating
    amountGigRate: 0,
    amountRate: 0,
    // Onboarding stuff
    answeredOnboarding: false,
    answeredOnboardingGeneral: false,
    stripeEnabled: false,
    video: "",
    videoVerified: false,
    // Credit
    credit: 0,
    // Email & Login
    email: "",
    emailEnabled: false,
    emailSentStatus: false,
    emailToken: "",
    loginToken: "",
    // Main data
    firstName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    flowType: [],
    flowTypeBasicCompleted: [],
    // Location
    lastLocation: "",
    lastVerification: USER_VERIFICATIONS.PHONE,
    // Status
    isActive: false,
    status: USER_STATUS.OUTLOGGED,
    // Role
    role: INITIAL_ROLE,
    settings: INITIAL_SETTINGS,
    zipcode: ""
};

// Initial values for Gig data
export const INITIAL_GIGDATA: GigWithRequirements = {
    id: "",
    flowType: GIG_TYPES.COUNCIL_MEMBERS,
    // Rating
    amountRate: 0,
    amountUsersRate: 0,
    // Timestamps
    dateEnd: "",
    dateStart: "",
    // Main data
    name: "",
    description: "",
    notes: "",
    image: "",
    locations: [],
    // Participants
    participants: 0,
    participantsLeft: 0,
    // Payments
    payment: "",
    priceToRecruit: "",
    // Translation
    sampleSource: "",
    // Surveys
    survey: "",
    reScreenSurvey: "",
    // Translations
    requirements: [],
    targetLanguage: [],
    source: "",
    sourceLanguage: ""
};
