import Container, { ContainerProps } from "@mui/material/Container";

import { styled } from "@mui/material/styles";

export const MWContainer = styled(Container)<ContainerProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "78px 24px"
}));
