import type { FC, Dispatch, SetStateAction, ReactNode } from "react";
import type { User } from "types/user";

import { createContext, useContext, useState } from "react";
import { INITIAL_USERDATA } from "constants/default";

interface FormData {
    userData: User;
    setUserData: Dispatch<SetStateAction<User>>;
}

export const UserContext = createContext<FormData>({
    userData: INITIAL_USERDATA,
    setUserData: userData => userData
});

export const UserContextProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const [userData, setUserData] = useState(INITIAL_USERDATA);

    return (
        <UserContext.Provider value={{ userData, setUserData }}>
            {children}
        </UserContext.Provider>
    );
};

// Hook to get the context data
export const useUserContext = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUserContext must be used within a Provider");
    }
    return context;
};
