import { Box, Typography, useTheme } from "@mui/material";

const RandomMessages = () => {
    const theme = useTheme();

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            px='20px'
            mb={3}>
            <Box
                display='flex'
                bgcolor={
                    theme.palette.mode === "light"
                        ? "#FCF7FF"
                        : theme.palette.background.paper
                }
                borderRadius='8px'
                width='100%'
                px={2}
                py={2}>
                <img src='/images/icon-idea.svg' width={40} alt='Light icon' />
                <Box ml={1}>
                    <Typography
                        color={theme.palette.text.secondary}
                        fontWeight={600}>
                        Did you know?
                    </Typography>
                    <Typography
                        fontSize='0.85rem'
                        color={theme.palette.text.secondary}>
                        House cats share 95.6% of their genetic makeup with
                        tigers.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default RandomMessages;
