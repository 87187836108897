import {
    createContext,
    Dispatch,
    FC,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";

type THEMES = "light" | "dark";

interface ThemeData {
    theme: THEMES;
    setTheme: Dispatch<SetStateAction<THEMES>>;
}

export const ThemeContext = createContext<ThemeData>({
    theme: "light",
    setTheme: theme => theme
});

export const ThemeContextProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const [theme, setTheme] = useState<THEMES>("light");

    useEffect(() => {
        const theme = localStorage.getItem("theme");
        if (theme) setTheme(theme as THEMES);
    }, []);

    useEffect(() => {
        localStorage.setItem("theme", String(theme));
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Hook to get the context data
export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (context === undefined) {
        throw new Error("useThemeContext must be used within a Provider");
    }
    return context;
};
