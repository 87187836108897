// Axios
import axios from "axios";

import type { Settings } from "types/user/settings";

import { getUserInfo } from "api";
import getEnvVariable from "env";

const apiUrl = getEnvVariable("API_URL");
const { token } = getUserInfo(); // Token

// Axios instance
const instance = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
    }
});

/**
 * @desc Get user settings by ID
 * @param {string} id ID of the User
 * @return {Promise<{ data: null; error: any;} | { data: Settings; error: null; }>} User settings or error
 * @author
 */
const getSettings = async (
    id: string
): Promise<{ data: null; error: any } | { data: Settings; error: null }> => {
    try {
        const response = await instance.get(
            `/api/me/getUserSetting?id=${id}&relations=["userLanguages"]`
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.UserSetting as Settings,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

/**
 * @desc Update user settings
 * @param {Settings} data Data that includes all the settings and user ID
 * @return {Promise<{ data: null; error: any;} | { data: Settings; error: null; }>} User settings or error
 * @author
 */
const updateSettings = async (
    data: Settings
): Promise<{ data: null; error: any } | { data: Settings; error: null }> => {
    try {
        const response = await instance.post(
            `/api/me/UpdateUserSettings`,
            data
        );
        if (response.status !== 200)
            return { data: null, error: response.data.message };
        return {
            data: response.data.data.UserSetting as Settings,
            error: null
        };
    } catch (err: any) {
        return {
            data: null,
            error:
                err.response.data.message ||
                err.message ||
                "Something went wrong "
        };
    }
};

const UserSettings = {
    // Get settings by User ID
    get: (id: string) => getSettings(id),
    // Update user Settings
    update: (data: Settings) => updateSettings(data)
};

export default UserSettings;
