import ReactDOM from "react-dom/client";
import App from "./App";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import branch from "branch-sdk";
import WebFont from "webfontloader";
import { ThemeContextProvider } from "context/ThemeContext";
/* 
import * as serviceWorkerRegistration from "serviceWorkerRegistration"; */

// Sentry
Sentry.init({
    dsn: "https://4dc313ba10044ada804dee780a8aae64@o1296031.ingest.sentry.io/6522315",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
});

// Branch SDK
const options = { no_journeys: true };
branch.init("key_live_gg5RZNDHDqU4Fq1ubzVfWoaizwanqa7Z", options);

// Web Font loader
WebFont.load({
    google: {
        families: ["Poppins: 400,500,600,700"]
    }
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <ThemeContextProvider>
        <App />
    </ThemeContextProvider>
);

// If you want your app to work offline and load faster, you can change
/* serviceWorkerRegistration.register({
    onUpdate: async (registration: any) => {
        if (registration && registration.waiting) {
            await registration.unregister();
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
            window.location.reload();
        }
    }
});
 */
