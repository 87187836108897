import type { FC, Dispatch, SetStateAction, ReactNode } from "react";
import type { Gig, GigForAmbassador } from "types/gigs";

import { createContext, useContext, useState } from "react";

interface FormData {
    gigs: Gig[];
    setGigs: Dispatch<SetStateAction<Gig[]>>;
    gigsForAmbassador: GigForAmbassador[];
    setGigsForAmbassador: Dispatch<SetStateAction<GigForAmbassador[]>>;
}

export const GigsContext = createContext<FormData>({
    gigs: [],
    setGigs: gigs => gigs,
    gigsForAmbassador: [],
    setGigsForAmbassador: gigsForAmbassador => gigsForAmbassador
});

export const GigsContextProvider: FC<{ children: ReactNode }> = ({
    children
}) => {
    const [gigs, setGigs] = useState<Gig[]>([]);
    const [gigsForAmbassador, setGigsForAmbassador] = useState<
        GigForAmbassador[]
    >([]);

    return (
        <GigsContext.Provider
            value={{ gigs, setGigs, gigsForAmbassador, setGigsForAmbassador }}>
            {children}
        </GigsContext.Provider>
    );
};

// Hook to get the context data
export const useGigsContext = () => {
    const context = useContext(GigsContext);
    if (context === undefined) {
        throw new Error("useGigsContext must be used within a Provider");
    }
    return context;
};
