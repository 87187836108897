import { LoadingButton } from "@mui/lab";
import Button, { ButtonProps } from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";

export const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    width: "100%",
    textTransform: "none",
    color:
        theme.palette.mode === "light"
            ? theme.palette.primary.contrastText
            : "#AB78B7",
    fontSize: "0.938rem",
    fontWeight: 500,
    borderRadius: "5px",
    textDecoration: "none",
    letterSpacing: "-0.36px",
    backgroundColor:
        theme.palette.mode === "dark"
            ? alpha(theme.palette.primary.main, 0.2)
            : theme.palette.primary.main,
    padding: "14px 2rem",
    ":hover": {
        backgroundColor:
            theme.palette.mode === "dark"
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.primary.main
    },
    ":focus": {
        backgroundColor:
            theme.palette.mode === "dark"
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.primary.main
    },
    "&.Mui-disabled": {
        backgroundColor: theme.palette.mode === "dark" ? "#202020" : "gainsboro"
    }
}));

export const PaymentButton = styled(LoadingButton)<ButtonProps>(
    ({ theme }) => ({
        width: "100%",
        maxWidth: "160px",
        gap: 5,
        textTransform: "none",
        color:
            theme.palette.mode === "light"
                ? theme.palette.primary.contrastText
                : "#82B54F",
        fontSize: "0.938rem",
        fontWeight: 500,
        borderRadius: "5px",
        textDecoration: "none",
        letterSpacing: "-0.36px",
        backgroundColor:
            theme.palette.mode === "dark" ? alpha("#B4FF3B", 0.15) : "#ADD56C",
        padding: "14px 1rem",
        ":hover": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? alpha("#B4FF3B", 0.15)
                    : "#ADD56C"
        },
        ":focus": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? alpha("#B4FF3B", 0.15)
                    : "#ADD56C"
        },
        "&.Mui-disabled": {
            backgroundColor:
                theme.palette.mode === "dark" ? "#202020" : "gainsboro"
        }
    })
);

export const TranslationButton = styled(LoadingButton)<ButtonProps>(
    ({ theme }) => ({
        width: "100%",
        maxWidth: "160px",
        gap: 5,
        textTransform: "none",
        color:
            theme.palette.mode === "light"
                ? theme.palette.primary.contrastText
                : "#926A96",
        fontSize: "0.938rem",
        fontWeight: 500,
        borderRadius: "5px",
        textDecoration: "none",
        letterSpacing: "-0.36px",
        backgroundColor:
            theme.palette.mode === "dark" ? alpha("#A060CA", 0.15) : "#946CD5",
        padding: "14px 1rem",
        ":hover": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? alpha("#A060CA", 0.15)
                    : "#946CD5"
        },
        ":focus": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? alpha("#A060CA", 0.15)
                    : "#946CD5"
        },
        "&.Mui-disabled": {
            backgroundColor:
                theme.palette.mode === "dark" ? "#202020" : "gainsboro"
        }
    })
);

export const CustomLoadingButton = styled(LoadingButton)<ButtonProps>(
    ({ theme }) => ({
        width: "100%",
        textTransform: "none",
        color:
            theme.palette.mode === "light"
                ? theme.palette.primary.contrastText
                : "#AB78B7",
        fontSize: "0.938rem",
        fontWeight: 600,
        borderRadius: "5px",
        backgroundColor:
            theme.palette.mode === "dark"
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.primary.main,
        padding: "14px 2rem",
        ":hover": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? alpha(theme.palette.primary.main, 0.2)
                    : theme.palette.primary.main
        },
        ":focus": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? alpha(theme.palette.primary.main, 0.2)
                    : theme.palette.primary.main
        },
        ".MuiLoadingButton-loadingIndicator": { color: "#FFF" },
        "&.Mui-disabled": {
            backgroundColor:
                theme.palette.mode === "dark" ? "#202020" : "gainsboro"
        }
    })
);

export const BackButton = styled(Button)<ButtonProps>(({ theme }) => ({
    width: 40,
    height: 40,
    display: "flex",
    color: "inherit",
    minWidth: "unset",
    borderRadius: 6,
    backgroundColor: theme.palette.grey[100],
    alignItems: "center",
    justifyContent: "center",
    ":hover": { backgroundColor: theme.palette.grey[100] },
    ":focus": { backgroundColor: theme.palette.grey[100] }
}));

export const ChatButton = styled(Button)<ButtonProps>(({ theme }) => ({
    width: 40,
    height: 40,
    display: "flex",
    color: "inherit",
    minWidth: "unset",
    borderRadius: 6,
    backgroundColor: theme.palette.grey[100],
    alignItems: "center",
    justifyContent: "center",
    ":hover": { backgroundColor: theme.palette.grey[100] },
    ":focus": { backgroundColor: theme.palette.grey[100] }
}));

export const ResendButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
    display: "flex",
    width: "50%",
    minWidth: "unset",
    fontSize: "0.938rem",
    textTransform: "none",
    borderRadius: 6,
    alignItems: "center",
    justifyContent: "center",
    ".MuiLoadingButton-loadingIndicator": { color: "#FFF" }
}));

export const DisabledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    width: "70%",
    textTransform: "none",
    fontSize: "0.938rem",
    fontWeight: 400,
    color: "#707070",
    borderRadius: "5px",
    backgroundColor:
        theme.palette.mode === "light" ? theme.palette.grey[100] : "#202020",
    ":hover": {
        backgroundColor:
            theme.palette.mode === "light" ? theme.palette.grey[100] : "#202020"
    },
    ":focus": {
        backgroundColor:
            theme.palette.mode === "light" ? theme.palette.grey[100] : "#202020"
    },
    padding: "13px 2rem",
    gap: "10px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)"
}));

export const CustomMiniButton = styled(Button)<ButtonProps>(({ theme }) => ({
    textTransform: "none",
    color:
        theme.palette.mode === "light"
            ? theme.palette.primary.contrastText
            : "#AB78B7",
    fontSize: "0.9rem",
    fontWeight: 600,
    borderRadius: "5px",
    backgroundColor:
        theme.palette.mode === "dark"
            ? alpha(theme.palette.primary.main, 0.2)
            : theme.palette.primary.main,
    padding: "7px 33px 8px 33px",
    ":hover": {
        backgroundColor:
            theme.palette.mode === "dark"
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.primary.main
    },
    ":focus": {
        backgroundColor:
            theme.palette.mode === "dark"
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.primary.main
    }
}));

export const ShareButton = styled(Button)<ButtonProps>(({ theme }) => ({
    textTransform: "none",
    color: theme.palette.grey[300],
    fontSize: "0.9rem",
    border: '1px solid #7E7E7E',
    fontWeight: 600,
    borderRadius: "5px",
    backgroundColor: "transparent",
    padding: "14px 16px"
}));
