// React & React Router DOM
import { lazy } from "react";
import { createBrowserHistory } from "history";
import {
    Routes,
    Route,
    unstable_HistoryRouter as HistoryRouter
} from "react-router-dom";
import { ScrollToTop } from "utils";

// Layouts
import NoLayout from "layouts/NoLayout";
// import MainLayout from "layouts/MainLayout";
// import SuspenseLayout from "layouts/SuspenseLayout";
// import OnBoardingLayout from "layouts/OnBoardingLayout";

import ProtectedRoute from "./ProtectedRoute";

// // Pages
// import Homepage from "pages/Home";

// // Chat
// const Chat = lazy(() => import("pages/Chat/Dashboard"));
// const ChatSupport = lazy(() => import("pages/Chat/Support"));
// const ChatSingleConversation = lazy(() => import("pages/Chat/Conversation"));

// // Gigs
// const Gig = lazy(() => import("pages/Gig"));
// const Progress = lazy(() => import("pages/Progress/Dashboard"));
// const GigTracker = lazy(() => import("pages/Progress/GigTracker"));
// const Success = lazy(() => import("pages/Gig/Pages/Success"));
// const ReScreener = lazy(() => import("pages/Gig/Pages/Rescreen"));
// const Error = lazy(() => import("pages/Gig/Pages/Error"));

// // Settings
// const Settings = lazy(() => import("pages/Settings"));
// const About = lazy(() => import("pages/About"));
// const Notifications = lazy(() => import("pages/Notifications"));
// const Visual = lazy(() => import("pages/Visual"));

// const Languages = lazy(() => import("pages/Languages"));
// const Security = lazy(() => import("pages/Security"));
// const ChangePhoneNumber = lazy(() => import("pages/Security/Phone"));
// const ChangeEmail = lazy(() => import("pages/Security/Email"));
// const ChangeZipcode = lazy(() => import("pages/Security/Zipcode"));

// //Payments
// const Payments = lazy(() => import("pages/Payments"));
// const CashOut = lazy(() => import("pages/Payments/CashOut"));
// const PaymentSuccess = lazy(() => import("pages/Payments/Success"));

// // Onboarding
const Start = lazy(() => import("pages/Start/Register"));
// // const Login = lazy(() => import("pages/Start/Login/Login"));

// const Onboarding = lazy(() => import("pages/Onboarding/Screens"));
// const Ways = lazy(() => import("pages/Onboarding/Screens/MoreWays"));
// const Zipcode = lazy(() => import("pages/Verification/Zipcode"));
// const Email = lazy(() => import("pages/Verification/Email"));
// const EmailVerification = lazy(
//     () => import("pages/Verification/EmailVerification")
// );
// const Identity = lazy(() => import("pages/Verification/Identity"));
// const Video = lazy(() => import("pages/Verification/Video"));

// // completed
// const Completed = lazy(() => import("pages/Onboarding/Screens/Completed"));
// const CompletedFlowType = lazy(
//     () => import("pages/Onboarding/Screens/CompletedFlowType")
// );
// const CompletedVerification = lazy(
//     () => import("pages/Onboarding/Screens/CompletedVerification")
// );

// // Other
// const Referal = lazy(() => import("pages/Referal"));
const Error404 = lazy(() => import("pages/404"));

const Router = () => {
    return (
        <HistoryRouter history={createBrowserHistory()}>
            <ScrollToTop />
            <Routes>
                <Route element={<ProtectedRoute />}>
                    {/* <Route element={<MainLayout />}> */}
                    <Route path='*' element={<Error404 />} />
                    {/*  <Route path='/homepage' element={<Homepage />} />
                        <Route path='/chat/support' element={<ChatSupport />} />
                        <Route path='/chat' element={<Chat />} />
                        <Route
                            path='/chat/:chatId'
                            element={<ChatSingleConversation />}
                        />
                        <Route path='/progress' element={<Progress />} />
                        <Route
                            path='/progress/gig/:id'
                            element={<GigTracker />}
                        />
                        <Route path='/settings' element={<Settings />} />
                        <Route path='/settings/about' element={<About />} />
                        <Route
                            path='/settings/notifications'
                            element={<Notifications />}
                        />
                        <Route path='/settings/visual' element={<Visual />} />
                        <Route
                            path='/settings/security'
                            element={<Security />}
                        />
                        <Route
                            path='/settings/languages'
                            element={<Languages />}
                        />
                        <Route
                            path='/settings/security/change-number'
                            element={<ChangePhoneNumber />}
                        />
                        <Route
                            path='/settings/security/change-email'
                            element={<ChangeEmail />}
                        />
                        <Route
                            path='/settings/security/change-zipcode'
                            element={<ChangeZipcode />}
                        />
                        <Route
                            path='/settings/payments'
                            element={<Payments />}
                        />
                        <Route
                            path='/settings/payments/cashout'
                            element={<CashOut />}
                        />
                        <Route
                            path='/settings/payments/cashout/success'
                            element={<PaymentSuccess />}
                        />
                        <Route path='/gig/success' element={<Success />} />
                        <Route path='/gig/error' element={<Error />} />
                        <Route path='/gig/rescreen' element={<ReScreener />} />
                    </Route>
                    <Route element={<OnBoardingLayout />}>
                        <Route path='/onboarding' element={<Onboarding />} />
                        <Route
                            path='/onboarding/basic/type/:type'
                            element={<Onboarding />}
                        />
                        <Route
                            path='/onboarding/how-do-you-want-to-make-extra-money'
                            element={<Ways />}
                        />
                        <Route path='/verify/zipcode' element={<Zipcode />} />
                        <Route path='/verify/email' element={<Email />} />
                        <Route path='/verify/identity' element={<Identity />} />
                        <Route path='/verify/video' element={<Video />} />
                        <Route
                            path='/verify/about-you'
                            element={<Onboarding />}
                        />
                        <Route
                            path='/onboarding/completed'
                            element={<Completed />}
                        />
                        <Route
                            path='/onboarding/completed/type/:type'
                            element={<CompletedFlowType />}
                        />
                        <Route
                            path='/verification/completed/:type'
                            element={<CompletedVerification />}
                        />
                    </Route>*/}
                </Route>
                {/* <Route
                    path='/security/email/verify/'
                    element={<EmailVerification />}
                />
                <Route element={<SuspenseLayout />}>
                    <Route path='/gig/:id' element={<Gig />} />
                </Route>
                <Route element={<SuspenseLayout />}>
                    <Route path='/gig/:id/recruit/:recruit' element={<Gig />} />
                </Route>
                <Route path='/referal/:id' element={<Referal />} /> */}
                <Route element={<NoLayout />}>
                    <Route path='/start' element={<Start />} />
                    {/* <Route path='/login' element={<Login />} /> */}
                </Route>
            </Routes>
        </HistoryRouter>
    );
};

export default Router;
