import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Scroll to top when the user changes the page.
const ScrollToTop = () => {
    // Get the current location.
    const { pathname } = useLocation();

    // Scroll to top when the pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
};

export default ScrollToTop;
