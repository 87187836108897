export enum GIG_TYPES {
    COUNCIL_MEMBERS = "councilMembers",
    COUNCIL_MEMBERS_EXPRESS = "councilMembersExpress",
    COMMUNITY_ARCHITECTS = "communityArchitects",
    GREAT_CONNECTORS = "greatConnectors",
    AMBASSADORS = "ambassadors",
    CURIOUS_CONSUMERS = "curiousConsumers",
    GLOBAL_COMMUNICATORS = "globalCommunicators"
}

export enum GIG_TYPES_IMAGE {
    COUNCIL_MEMBERS = "/images/flow_types/1_cm-basic.png",
    COUNCIL_MEMBERS_EXPRESS = "/images/flow_types/2_cmexp-basic.png",
    COMMUNITY_ARCHITECTS = "/images/flow_types/3_carc-basic.png",
    GREAT_CONNECTORS = "/images/flow_types/4_gcon-basic.png",
    AMBASSADORS = "/images/flow_types/5_amb-basic.png",
    CURIOUS_CONSUMERS = "/images/flow_types/6_cc-basic.png",
    GLOBAL_COMMUNICATORS = "/images/flow_types/7_gcom-basic.png"
}

export interface HIVEMIND_GIG_CONFIG {
    _id?: string;
    _databaseId: GIG_TYPES;
    name: string;
    shortDescription: string;
    longDescription: string;
    emoji?: string;
    icon?: string;
    color?: string;
    selected?: boolean;
    basic?: boolean;
    image?: GIG_TYPES_IMAGE;
}

export const HIVEMIND_GIGS: HIVEMIND_GIG_CONFIG[] = [
    {
        _databaseId: GIG_TYPES.COUNCIL_MEMBERS,
        name: `Reviewer`,
        shortDescription: `Get paid to tell us what you think about things.`,
        longDescription: `You can get paid to participate in research studies that you qualify for. These include surveys, online focus groups, online communities, in-home product tests and more!`,
        emoji: ``,
        icon: `/images/ways_icons/h-reviewer.png`,
        color: "#EAA572",
        image: GIG_TYPES_IMAGE.COUNCIL_MEMBERS
    },
    {
        _databaseId: GIG_TYPES.COMMUNITY_ARCHITECTS,
        name: `Builder`,
        shortDescription: `Help us grow 10K Voices by inviting others to join.`,
        longDescription: `Help others in your personal and professional networks earn money for gigs by referring them to join 10K Voices. You’ll earn money for each person that signs up.`,
        emoji: ``,
        icon: `/images/ways_icons/h-builder.png`,
        color: "#9FCEDA",
        image: GIG_TYPES_IMAGE.COMMUNITY_ARCHITECTS
    },
    {
        _databaseId: GIG_TYPES.GREAT_CONNECTORS,
        name: `Interceptor`,
        shortDescription: `Ask people questions at festivals, street fairs and more!`,
        longDescription: `Conduct short interviews via smartphone or tablet with people at different locations like shopping stores, music events, festivals and more. You will earn money for each interview you complete.`,
        emoji: ``,
        icon: `/images/ways_icons/h-networker.png`,
        color: "#7FA3E5",
        image: GIG_TYPES_IMAGE.GREAT_CONNECTORS
    },
    {
        _databaseId: GIG_TYPES.AMBASSADORS,
        name: `Networker`,
        shortDescription: `Find people for projects through your personal and professional networks.`,
        longDescription: `Sometimes we need particular people to talk about a particular topic at a particular date and time.  You can earn money by helping us find those specific people and getting them to fully complete the project.`,
        emoji: ``,
        icon: `/images/ways_icons/h-interceptor.png`,
        color: "#E8D17F",
        image: GIG_TYPES_IMAGE.AMBASSADORS
    },
    {
        _databaseId: GIG_TYPES.CURIOUS_CONSUMERS,
        name: `Shopper`,
        shortDescription: `Go shopping (undercover!) and tell us about your experience.`,
        longDescription: `You can earn money by going into different stores and evaluating them on your phone or tablet! You will provide feedback on things like cleanliness, customer service and more!`,
        emoji: ``,
        icon: `/images/ways_icons/h-shopper.png`,
        color: "#E87F7F",
        image: GIG_TYPES_IMAGE.CURIOUS_CONSUMERS
    },
    {
        _databaseId: GIG_TYPES.GLOBAL_COMMUNICATORS,
        name: `Translator`,
        shortDescription: `Help global brands translate documents, videos and more.`,
        longDescription: `The perfect gig for people who can read and write in multiple languages. If you can, then you can earn money by translating documents from one language to another.`,
        emoji: ``,
        icon: `/images/ways_icons/h-translator.png`,
        color: "#CC7BDB",
        image: GIG_TYPES_IMAGE.GLOBAL_COMMUNICATORS
    }
];
