import RandomMessages from "components/RandomMessages";
import { Box, Typography, Grow } from "@mui/material";
import { FullHeightContainer } from "styled-components/Boxes";
import { CustomButton } from "styled-components/Buttons";
import { MWContainer } from "styled-components/Containers";

const NotFound = () => {
    return (
        <Grow in={true}>
            <FullHeightContainer
                sx={{
                    height: "auto",
                    marginTop: "4rem",
                    marginBottom: "3rem"
                }}>
                <MWContainer maxWidth='xs' sx={{ padding: "20px" }}>
                    <img alt='Check icon' src='/images/icon-failure.svg' />

                    <Typography
                        variant='h2'
                        textAlign='center'
                        maxWidth={320}
                        mt={4}>
                        We’re facing an unexpected error
                    </Typography>
                    <Typography textAlign='center' maxWidth={300} mt={3}>
                        Don’t fret - just press the button below to come back
                    </Typography>

                    <CustomButton
                        sx={{ margin: "40px 0", width: "60%" }}
                        onClick={() => (window.location.href = "/")}>
                        Go back
                    </CustomButton>

                    <Box width='100%'>
                        <RandomMessages />
                    </Box>
                </MWContainer>
            </FullHeightContainer>
        </Grow>
    );
};

export default NotFound;
