import axios from "axios";
import { DataType } from "types";
import getEnvVariable from "env";

import { RegisterData } from "types/user/register";
import { LocalStorageUserInfo } from "types/user";
import { getSearchParams } from "utils";
import { Gig } from "types/gigs";

const apiUrl = getEnvVariable("API_URL");

export const generateApiConection = getEnvVariable("API_URL");

export const getUserInfo: () => LocalStorageUserInfo = () => {
    const info = localStorage.getItem("userInfo");
    return info
        ? JSON.parse(info)
        : {
              token: "",
              id: "",
              firstName: "",
              phoneNumber: "",
              lastName: "",
              didAnswer: false
          };
};

export const setUserInfo = ({
    token,
    id,
    phoneNumber,
    firstName,
    lastName,
    didAnswer
}: any) => {
    localStorage.setItem(
        "userInfo",
        JSON.stringify({
            token,
            id,
            firstName,
            lastName,
            didAnswer,
            phoneNumber
        })
    );
};

export const GetMe = async (token: string) => {
    try {
        const response = await axios({
            url: `${apiUrl}/api/me/myinfo`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            localStorage.removeItem("userInfo");
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const createSignature = async () => {
    const { token } = getUserInfo();

    try {
        const response = await fetch(`${apiUrl}/api/me/createSignature`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });
        return response.json() as any;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const FinishOnboarding = async (id: string) => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/me/onboardingfinished?userId=${id}`,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const LoginUser = async (phoneNumber: string, code: string) => {
    try {
        const response = await axios({
            url: `${apiUrl}/api/me/login`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                phoneNumber,
                code
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const registerUser = async ({
    firstName,
    lastName,
    leadSource,
    leadParams,
    phoneNumber
}: RegisterData) => {
    try {
        const response = await axios({
            url: `${apiUrl}/api/me/register?${leadParams || ""}`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                firstName,
                lastName,
                phoneNumber,
                leadSource,
                leadParams
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const SendSMS = async (
    phoneNumber: string,
    changePhoneNumber?: string,
    token?: string
) => {
    const normal = { phoneNumber, token };
    const withChange = { phoneNumber, changePhoneNumber, token };
    const { mode } = getSearchParams();
    const data = changePhoneNumber ? withChange : normal;
    const params = mode ? `?mode=${mode}` : "";
    try {
        const response = await axios({
            url: `${apiUrl}/api/me/sendSms${params}`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const EnablePhone = async (phoneNumber: string, code: string) => {
    try {
        const response = await axios({
            url: `${apiUrl}/api/me/enablePhoneNumber`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                phoneNumber,
                code
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const getAllQuestions = async () => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/questions/getAll?flowType=onboarding`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data.data.questions;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const getQuestionsByFlowType = async (flowType: string) => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/questions/getByFlowType?flowType=${flowType}`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data.data.questions;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const getOptionsById = async (id: string) => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/questions/getAll?relations=["options"]&id=${id}&orderBy=options`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const GetAllGigs = async () => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/gigs/get`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const SendEmail = async (data: DataType, reCaptchaToken: string) => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/me/sendEmail`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: {
                ...data,
                token: reCaptchaToken
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const UploadVideo = async (id: string, video: string) => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/me/uploadvideo?userId=${id}`,
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data: {
                video
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const VerifyEmail = async (token: string) => {
    try {
        const response = await axios({
            url: `${apiUrl}/api/me/enableEmail?token=${token}`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const sendNetworkerEmail = async (data: {
    email: string;
    phoneNumber: string;
    type: string;
    gig: Gig;
    userToGigId: string;
    quota: number;
}) => {
    const { token } = getUserInfo();
    try {
        const response = await axios({
            url: `${apiUrl}/api/me/sendNetworkerEmail`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return err;
    }
};

export const AnswerQuestion = async (data: any) => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/answer/create`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            data
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

export const GetGigsByUser = async () => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/gigs/getmygigs`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};

// ambassador gigs list
export const GetAllAmbassadorsGigs = async () => {
    const { token } = getUserInfo();

    try {
        const response = await axios({
            url: `${apiUrl}/api/gigs/getmygigsforambassadors`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
        return response.data;
    } catch (err: any) {
        return { error: err.response.data.message || err.message };
    }
};
