// Router
import Router from "router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Material UI
import lightTheme from "./theme/light";
import darkTheme from "theme/dark";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { AnimatePresence } from "framer-motion";
import { MainContainer } from "styled-components/Boxes";
import { UserContextProvider } from "context/UserContext";
import { GigsContextProvider } from "context/GigsContext";
import { useThemeContext } from "context/ThemeContext";
import { ConversationContextProvider } from "context/conversations/ConversationsContext";

import ErrorBoundary from "router/ErrorBoundary";
import mixpanel from "mixpanel-browser";
import reactGA from "react-ga";
import getEnvVariable from "env";

const snackBarProps = {
    anchorOrigin: {
        vertical: "top" as "top",
        horizontal: "center" as "center"
    },
    autoHideDuration: 3000,
    preventDuplicate: true,
    maxSnack: 3
};

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } }
});

const mixpanelToken = getEnvVariable("MIXPANEL_TOKEN");
const gaTrackingId = getEnvVariable("TRACKING_ID");
mixpanel.init(mixpanelToken);
reactGA.initialize(gaTrackingId);

const App = () => {
    const { theme } = useThemeContext();
    return (
        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider {...snackBarProps}>
                    <UserContextProvider>
                        <GigsContextProvider>
                            <ConversationContextProvider>
                                <ErrorBoundary>
                                    <AnimatePresence>
                                        <MainContainer>
                                            <Router />
                                        </MainContainer>
                                    </AnimatePresence>
                                </ErrorBoundary>
                            </ConversationContextProvider>
                        </GigsContextProvider>
                    </UserContextProvider>
                </SnackbarProvider>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default App;
