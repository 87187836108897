import { CircularProgress } from "@mui/material";
import { FullHeightContainer } from "./Boxes";
import { MWContainer } from "./Containers";

export const ViewsSliderStyles = {
    delay: "0s",
    duration: ".25s",
    easeFunction: "ease-in"
};

export const Loader = () => (
    <FullHeightContainer>
        <MWContainer>
            <CircularProgress />
        </MWContainer>
    </FullHeightContainer>
);
